import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Layout = ({ data, children }) => (
  <>
    <GoogleReCaptchaProvider reCaptchaKey="6LdpxosdAAAAAFenROCUFSGek6GYrHvH7MiQ3zVT">
      <Header data={data} />
      <>{children}</>
      <Footer />
    </GoogleReCaptchaProvider>
  </>
);

export default Layout;
