import { Link, graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import React from "react";
import ContactForm from "../Global/ContactForm";
import InView from "../InView";

export default function Footer() {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			sanitySiteSettings {
				acknowledgement
				newsletterCTATitle
				newsletterCTAText
				newsletterRightTitle
			}
		}
	`);

	const acknowledgement = data.sanitySiteSettings?.acknowledgement;
	const newsletterCTATitle = data.sanitySiteSettings?.newsletterCTATitle;
	const newsletterCTAText = data.sanitySiteSettings?.newsletterCTAText;
	const newsletterRightTitle = data.sanitySiteSettings?.newsletterRightTitle
	const location = useLocation();
	const isContactUs = location.pathname.startsWith("/contact-us");

	function renderContactForm() {
		if(isContactUs) {
			return;
		}

		return (
			<div className="container bg-off-white border-b border-grey-default box-border flex flex-col md:grid md:grid-cols-12 py-7 md:py-0 md:pt-10 gap-6 md:gap-10">
				<div className="flex flex-col md:pb-10 md:col-span-8 md:pr-10 order-1">
					{newsletterCTATitle && (
						<h3 className="max-w-[350px]">{newsletterCTATitle}</h3>
					)}
					<div className="mt-10 md:mt-auto max-w-[680px]">
						<h2 className="font-serif text-[32px] leading-[1.2] mb-2">
							{newsletterCTAText && <p>{newsletterCTAText}</p>}
						</h2>
					</div>
				</div>
				<div className="md:pb-10 md:border-grey-default box-border md:col-span-4 order-2 md:pl-10 md:border-l mt-10 md:mt-0">
					<div className="max-w-[500px]">
						{newsletterRightTitle && (<h3 className="text-[24px] leading-[1.4] font-serif mb-10">
							{newsletterRightTitle}
						</h3>)}
						<ContactForm />
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<footer>
				<InView>
					{renderContactForm()}
					<div className="container flex flex-col md:grid md:grid-cols-12 text-sm py-5 md:pt-[40px] md:pb-10">
						<div className="md:col-span-2 xl:col-span-2">
							<Link title="Homepage" className="inline-flex" to="/">
								<svg
									className="fill-current"
									xmlns="http://www.w3.org/2000/svg"
									width="73"
									height="25"
									fill="none"
								>
									<path d="M17.76 21.706H0v2.627h17.76v-2.627zm-5.393-9.956H4.574l-1.185 2.403c-.101.224-.237.507-.41.854a2.042 2.042 0 00-.266.877.756.756 0 00.21.568c.152.145.334.255.533.323.24.081.49.137.743.166.266.034.55.06.836.08v.464H.027L9.081 0h.533l8.1 17.485h-2.662l-2.684-5.735zM8.541 3.927L5.052 10.82h6.924L8.541 3.927zm14.718-.259c0-.528-.016-.98-.046-1.342a1.537 1.537 0 00-.319-.878 1.452 1.452 0 00-.85-.465 7.847 7.847 0 00-1.637-.13V.389h8.708c1.211-.038 2.42.124 3.579.479a6.018 6.018 0 012.13 1.173c.457.394.807.896 1.017 1.46.16.423.25.869.267 1.32a3.278 3.278 0 01-.41 1.652 4.305 4.305 0 01-1.092 1.266 6.032 6.032 0 01-1.577.891c-.614.238-1.25.415-1.899.529l3.915 4.649c.365.449.72.856 1.065 1.226.337.364.714.688 1.124.967a5.5 5.5 0 001.363.674c.58.186 1.182.298 1.79.336v.465H35l-7.374-8.782c.626 0 1.25-.06 1.864-.18a4.695 4.695 0 001.622-.621 3.296 3.296 0 001.2-1.269c.278-.522.409-1.11.379-1.7a3.553 3.553 0 00-.288-1.434 3.286 3.286 0 00-.836-1.147 3.894 3.894 0 00-1.302-.764 4.957 4.957 0 00-1.67-.265c-.22 0-.476 0-.759.038-.28.026-.552.11-.798.245-.28.167-.506.41-.653.698-.2.428-.292.898-.266 1.37V17.48h-2.86V3.668zM48.623.388h-2.852v17.095h2.852V.388zM67.631 11.75h-7.797l-1.185 2.403c-.099.224-.237.507-.407.853a2.033 2.033 0 00-.267.878.744.744 0 00.213.568c.152.145.334.255.533.322.24.083.49.139.743.167.266.034.548.06.833.08v.464h-5.006L64.353.01h.546l8.1 17.486h-2.662L67.63 11.75zm-3.824-7.823L60.32 10.82h6.923l-3.435-6.893z"></path>
								</svg>
							</Link>
						</div>
						<div className="max-w-[540px] mx-auto lg:text-center md:col-span-6 md:pr-8 lg:pr-0 xl:col-span-8 order-3 md:order-2">
							{acknowledgement && <p>{acknowledgement}</p>}
						</div>
						<div className="flex mt-8 mb-4 md:my-0 items-end md:col-span-3 md:col-start-10 xl:col-start-11 xl:col-span-2 order-2 md:order-3">
							<ul className="lg:w-2/3">
								<li>
									<Link
										className="transition-all duration-300 ease-in-out hover:text-grey-base"
										to="/privacy"
									>
										Privacy & Disclaimer
									</Link>
								</li>
							</ul>
							<p className="text-grey-base text-right ml-auto lg:ml-0 lg:w-1/3">
								&copy; {new Date().getFullYear()}
							</p>
						</div>
					</div>
				</InView>
			</footer>
		</>
	);
}
